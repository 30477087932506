<template>
        <div class="px-3 md:px-0 pb-4">
        
            <div v-if="isLoading" class="animate-pulse">
                <div class="header bg-gray-100 mb-8 h-60 px-5 py-4 pb-7 -mx-3">
                    <div class="h-6 w-24 bg-gray-200 rounded mb-2"/>
                    <div class="h-10 w-1/2 bg-gray-200 rounded mb-2"/>
                    <div class="h-5 w-2/3 bg-gray-200 rounded mb-2"/>
                    <div class="h-5 w-2/3 bg-gray-200 rounded mb-2"/>
                    <div class="h-5 w-1/3 bg-gray-200 rounded mb-2"/>
                    <div class="h-5 w-1/3 bg-gray-200 rounded"/>
                </div>

                <div class="grid lg:grid-cols-4 sm:grid-cols-2 gap-10 my-3 items-center ">
                    <div class="h-36 w-full bg-gray-100 rounded mb-2"/>
                    <div class="h-36 w-full bg-gray-100 rounded mb-2"/>
                    <div class="h-36 w-full bg-gray-100 rounded mb-2"/>
                </div>
            </div>

            <div v-if="kategori != null && isLoading == false">

                <div class="header bg-red-500 
                    px-5 py-4 pb-7 -mx-3
                    md:rounded-lg 
                    md:mt-0 md:mx-0
                ">
                    <div class="grid grid-cols-1 md:grid-cols-3">
                        <div class="col-span-2">
                            <h2 class="text-base text-gray-100 font-semibold tracking-wide uppercase">{{ kategori.singkatan }}</h2>
                            <p class="mt-1 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
                                {{ kategori.judul }}
                            </p>
                            <p class="mt-4 mb-4 text-xl text-gray-100">
                                {{ kategori.deskripsi }}
                            </p>
                        </div>

                        <div class="img px-5 hidden md:block">
                            <img :src="kategori.thumbnail" class="rounded-lg" />
                        </div>
                    </div>
                </div>

                <h1 class="mt-4 text-xl leading-8 font-semibold tracking-tight text-gray-900">
                    Daftar Kompetensi Dasar
                </h1>


                <div class="grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-4 gap-2 md:gap-10 sm:gap-2 my-3 items-center text-gray-600 hover:text-gray-800 transition-all">
                    <router-link :to="{name: 'MateriSubKategoriList', params : {
                        'singkatan_kategori' : kategori.singkatan.toLowerCase(),
                        'singkatan_sub_kategori' : data.singkatan.toLowerCase(),
                    }}" class="shadow-md rounded-lg bg-white py-4 px-3 flex flex-col items-center transition-all hover:bg-gray-50"
                        v-for="(data, index) in kategori.sub_kategori" :key="index">

                        <span class="p-2 rounded-full bg-yellow-100 h-16 w-16 text-center items-center flex align-middle">
                            <img src="@/assets/images/study.svg" class="h-10 w-10 mx-auto" />
                        </span>
                        <div class="text-center">
                            <h3 class="flex-auto font-semibold text-gray-800 mb-0.5 mt-3">{{ data.singkatan.toUpperCase() }}</h3>
                            <p class="text-based">{{ data.judul }}</p>
                        </div>
                        <div class="w-full h-3 rounded-lg bg-gray-200 mt-1 relative" v-if="data.persen_lulus != null">
                            <div class="h-full bg-yellow-500 rounded-lg text-center" :style="{ width: `${data.persen_lulus}%` }" >
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>
</template>
<script>
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    export default {
        name: 'DashboardKategori',
        data(){
            return {
                kategori : null,
                isLoading: true
            }
        },
        mounted() {
			this.getKategoriDetail();
		},
		methods : {
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori + '/dashboard')
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch(error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            }

        }
    }
</script>
